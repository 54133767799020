import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';


import {CustomersProvider} from "./state/context/CustomersContext";
import {TimeLogsProvider} from "./state/context/TimeLogsContext";
import {ProjectsProvider} from "./state/context/ProjectsContext";
import {ActivitiesProvider} from "./state/context/ActivitiesContext";

const { default: MainLayout } = require("./components/layout/MainLayout");

function App() {
  return (
    <div className="App">
        <ReactKeycloakProvider authClient={keycloak}>
            <TimeLogsProvider>
                <CustomersProvider>
                    <ProjectsProvider>
                        <ActivitiesProvider>
                            <Router>
                                <MainLayout/>
                            </Router>
                        </ActivitiesProvider>
                    </ProjectsProvider>
                </CustomersProvider>
            </TimeLogsProvider>
        </ReactKeycloakProvider>
    </div>
  );
}

export default App;
