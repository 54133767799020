import {
    ACTIVITIES_LOADING,
    ACTIVITIES_LOAD_ERROR,
    ACTIVITY_ADDED
} from "../../constants";

const addActivity = (dispatch, token, project) => {
    dispatch({
        type: ACTIVITIES_LOADING
    });

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(project)
    };

    let respStatus = 0
    fetch(process.env.REACT_APP_API_URL + "/activities", requestOptions)
        .then(resp => {
            respStatus = resp.status
            return resp.json()
        }).then(json => {
        if (respStatus !== 200) {
            dispatch({
                type: ACTIVITIES_LOAD_ERROR,
                payload: json
            })
        } else {
            dispatch({
                type: ACTIVITY_ADDED,
                payload: json
            });
        }
    }).catch(err => {
        dispatch({
            type: ACTIVITIES_LOAD_ERROR,
            payload: err
        })
    })
}

export default addActivity