import React from 'react'
import {DateTime} from 'luxon'
import {
    PROJECT_ADDED,
    PROJECT_SELECT_EDIT,
    PROJECT_EDIT_CANCEL,
    PROJECT_UPDATED,
    PROJECTS_LOAD_ERROR,
    PROJECTS_LOAD_SUCCESS,
    PROJECTS_LOADING,
} from "../constants";

const ProjectsContext = React.createContext()

const defaultState = {
    projects: [],
    editProject: null,
    loading: false,
    error: null
}

const projectsReducer = (state, action) => {
    switch (action.type) {
        case PROJECT_ADDED: {
            return {
                ...state,
                projects: [...state.projects, action.payload],
                loading: false
            };
        }
        case PROJECTS_LOADING: {
            return {
                ...state,
                loading: true
            }
        }
        case PROJECT_SELECT_EDIT: {
            return {
                ...state,
                editProject: action.payload
            }
        }
        case PROJECT_UPDATED: {
            let p = action.payload
            p.start = DateTime.fromISO(p.start)
            p.end = DateTime.fromISO(p.end)
            return {
                ...state,
                loading: false,
                editProject: null,
                projects: state.projects.map(c => c.id === action.payload.id ? p : c)
            }
        }
        case PROJECT_EDIT_CANCEL: {
            return {
                ...state,
                editProject: null
            }
        }
        case PROJECTS_LOAD_SUCCESS: {
            const p = action.payload.map(pr => {
                pr.start = DateTime.fromISO(pr.start)
                pr.end = DateTime.fromISO(pr.end)
                return pr
            })
            return {
                ...state,
                loading: false,
                projects: p
            }
        }
        case PROJECTS_LOAD_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function ProjectsProvider({children}) {
    const [state, dispatch] = React.useReducer(projectsReducer, defaultState)
    const value = {state, dispatch}
    return <ProjectsContext.Provider value={value}>{children}</ProjectsContext.Provider>
}

const useProjects = () => {
    const context = React.useContext(ProjectsContext)
    if (context === undefined) {
        throw new Error('useProjects must be used within a ProjectsProvider')
    }
    return context
}

export {ProjectsProvider, useProjects}