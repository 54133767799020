import React from 'react'

export default function CircleAddButton({className, onClick}) {

    const onClickButton = () => {
        onClick()
    }

    return (
        <div className={className}>
            <div
                onClick={onClickButton}
                className="h-10 w-10 rounded-full bg-primary text-center text-white font-light cursor-pointer select-none"
                style={{fontSize: "1.5em", lineHeight: "2.2rem"}}>+</div>
        </div>
    )
}
