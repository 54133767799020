import React, { forwardRef, useImperativeHandle, useRef } from 'react';

const NumberField = forwardRef(({min, max, value, displayLength, toLeft, toRight, onChange}, ref) => {

    let inputRef = useRef()

    useImperativeHandle(ref, () => ({

        setActive() {
            inputRef.current.focus()
            inputRef.current.select()
        }
    
    }));


    function onChangeValue(e) {

        if (e.target.value.length === 0) {
            e.target.value = displayStrValue(min)
            // e.target.setSelectionRange(0, 3, "forward")
            // console.log("set selection")
        }

        if (e.target.value.length > displayLength && e.target.value.startsWith("0")) {
            e.target.value = e.target.value.substring(1)
        }

        if (e.target.value.endsWith(".")) {
            if (toRight) {
                toRight()
            }
            
            return
        }

        if (!isNumeric(e.target.value)) {
            return
        }

        let value = parseInt(e.target.value)

        if (value < min) {
            value = min
            e.target.value = displayStrValue(min)
            // e.target.setSelectionRange(0, 4, "forward")
        }
        if (value > max) {
            value = max
        }

        if (onChange) {
            onChange(value)
        }

        if ( e.target.value.length === displayLength ) {
            if (toRight) {
                toRight()
            }
        } 
    }

    function onKeyDown(e) {
        // key up
        if (e.keyCode === 38) {
            e.preventDefault()
            let tmpValue = value
            tmpValue++

            if ( tmpValue > max ) {
                tmpValue = min
            }

            if (onChange) {
                onChange(tmpValue)
            }
            return
        }

        // key down
        if (e.keyCode === 40) {
            e.preventDefault()
            let tmpValue = value
            tmpValue--

            if ( tmpValue < min ) {
                tmpValue = max
            }

            if (onChange) {
                onChange(tmpValue)
            }
            return
        }
        
        // key left
        if (e.keyCode === 37) {
            if (e.target.selectionStart < 1) {
                e.preventDefault()
                if (toLeft) {
                    toLeft()
                }
            } 
        } 

        // key right
        if (e.keyCode === 39) {
            if (e.target.selectionStart > displayLength-1) {
                e.preventDefault()
                if (toRight) {
                    toRight()
                }
            } 
        } 

    }

    function isNumeric(num){
        return !isNaN(num)
    }

    function getDisplayValue() {
        let strValue = value.toString()
        return displayStrValue(strValue)
    }

    function displayStrValue(value) {
        let strValue = value.toString()
        let lengthDiff = displayLength - strValue.length
        if (lengthDiff > 0) {
            for (let i = 0; i < lengthDiff; i++) {
                strValue = "0" + strValue
            }
        }
        return strValue
    }

    const getWithClass = () => {
        if (displayLength > 2) {
            return "w-10"
        }
        return "w-5"
    }

    return (
        <input type="text"
                onClick={(e) => {e.target.select()}} 
                className={"w-5 outline-none text-center day " + getWithClass()}
                onChange={onChangeValue} 
                onKeyDown={onKeyDown}
                value={getDisplayValue()}
                ref={inputRef}
                />
    )
})

export default NumberField
