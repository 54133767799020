/* TIMELOG */
export const TIMELOGS_LOADING = "TIMELOGS_LOADING"
export const TIMELOG_ADDED = "TIMELOG_ADDED"
export const TIMELOG_SELECT_EDIT = "TIMELOG_SELECT_EDIT"
export const TIMELOG_UPDATED = "TIMELOG_UPDATED"
export const TIMELOG_EDIT_CANCEL = "TIMELOG_EDIT_CANCEL"
export const TIMELOGS_LOAD_SUCCESS = "TIMELOGS_LOAD_SUCCESS"
export const TIMELOGS_LOAD_ERROR = "TIMELOGS_LOAD_ERROR"

/* CUSTOMERS */
export const CUSTOMERS_LOADING = "CUSTOMERS_LOADING"
export const CUSTOMER_ADDED = "CUSTOMER_ADDED"
export const CUSTOMER_SELECT_EDIT = "CUSTOMER_SELECT_EDIT"
export const CUSTOMER_UPDATED = "CUSTOMER_UPDATED"
export const CUSTOMER_EDIT_CANCEL = "CUSTOMER_EDIT_CANCEL"
export const CUSTOMERS_LOAD_SUCCESS = "CUSTOMERS_LOAD_SUCCESS"
export const CUSTOMERS_LOAD_ERROR = "CUSTOMERS_LOAD_ERROR"

/* PROJECTS */
export const PROJECTS_LOADING = "PROJECTS_LOADING"
export const PROJECT_ADDED = "PROJECT_ADDED"
export const PROJECT_SELECT_EDIT = "PROJECT_SELECT_EDIT"
export const PROJECT_UPDATED = "PROJECT_UPDATED"
export const PROJECT_EDIT_CANCEL = "PROJECT_EDIT_CANCEL"
export const PROJECTS_LOAD_SUCCESS = "PROJECTS_LOAD_SUCCESS"
export const PROJECTS_LOAD_ERROR = "PROJECTS_LOAD_ERROR"

/* ACTIVITIES */
export const ACTIVITIES_LOADING = "ACTIVITIES_LOADING"
export const ACTIVITY_ADDED = "ACTIVITY_ADDED"
export const ACTIVITY_SELECT_EDIT = "ACTIVITY_SELECT_EDIT"
export const ACTIVITY_UPDATED = "ACTIVITY_UPDATED"
export const ACTIVITY_EDIT_CANCEL = "ACTIVITY_EDIT_CANCEL"
export const ACTIVITIES_LOAD_SUCCESS = "ACTIVITIES_LOAD_SUCCESS"
export const ACTIVITIES_LOAD_ERROR = "ACTIVITIES_LOAD_ERROR"