import React from "react";
import Loader from "./Loader";
import BasicTableRow from "./BasicTableRow";

export default function BasicTable({columnDefs, entries, loading, onClickEdit}) {

    const getHeaderCell = (def) => {
        switch (def.type) {
            case "button": {
                return (
                    <th className="sticky top-0 px-6 py-3 bg-white" key={def.key}/>
                )
            }
            default: {
                return (
                    <th className="sticky top-2 px-6 py-3 bg-white text-left text-xs leading-4 font-normal text-gray-500 uppercase tracking-wider" key={def.key}>
                        {def.title}
                    </th>
                )
            }
        }
    }

    const renderHeader = () => {
        return (
            <thead>
                <tr>
                {columnDefs.map(def => getHeaderCell(def))}
                </tr>
            </thead>
        )
    }

    const renderEntries = () => {
        return entries.map(entry => {
            return (
                <BasicTableRow columnDefs={columnDefs} entry={entry} onClickEdit={() => {onClickEdit(entry.id)}} key={entry.id}/>
            )
        })
    }

    const renderLoader = () => {
        if (loading) {
            return <Loader />
        }
    }

    return (
        <div>
            <div className="flex flex-col">
                <div className="overflow-x-auto">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="border-b max-content-height border-gray-200">
                            <table className="min-w-full scroll divide-y divide-gray-200">
                                {renderHeader()}
                                <tbody className="bg-white divide-y divide-gray-200 font-light">
                                {renderEntries()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {renderLoader()}
        </div>
    )
}