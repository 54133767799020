import {
    ACTIVITIES_LOAD_ERROR,
    ACTIVITIES_LOAD_SUCCESS,
    ACTIVITIES_LOADING
} from "../../constants";

const getActivities = (dispatch, token) => {
    dispatch({
        type: ACTIVITIES_LOADING,
    });

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    }

    fetch(process.env.REACT_APP_API_URL + "/activities", requestOptions)
        .then(resp => resp.json())
        .then(json => {
            dispatch({
                type: ACTIVITIES_LOAD_SUCCESS,
                payload: json
            });
        }).catch(err => {
            dispatch({
                type: ACTIVITIES_LOAD_ERROR,
                payload: err
            })
    })
}

export default getActivities