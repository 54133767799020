import React, {useState, useRef, useEffect} from 'react';
import Modal from "../layout/Modal";
import CircleAddButton from "../button/CircleAdd";
import BasicTable from "../layout/BasicTable";
import {useProjects} from "../../state/context/ProjectsContext";
import getProjects from "../../state/actions/projects/getProjects";
import {PROJECT_EDIT_CANCEL, PROJECT_SELECT_EDIT} from "../../state/constants";
import ProjectsForm from "./ProjectsForm";
import addProject from "../../state/actions/projects/addProject";
import updateProject from "../../state/actions/projects/updateProject";
import {useKeycloak} from "@react-keycloak/web";

export default function Projects() {

    const [showModal, setShowModal] = useState(false);
    const formRef = useRef()
    const {dispatch, state} = useProjects()
    const {keycloak, initialized} = useKeycloak();

    useEffect(() => {
        if (!initialized) {
            return
        }

        if (!keycloak.authenticated) {
            keycloak.login()
            return
        }

        getProjects(dispatch, keycloak.token)
    }, [dispatch, initialized, keycloak.authenticated])

    useEffect(() => {
        if (state.editProject) {
            setShowModal(true)
        }
    }, [state.editProject])

    const renderModal = () => {
        if (showModal) {
            return <Modal onClose={onCloseModal} onSave={onSaveModal}>
                <ProjectsForm ref={formRef}/>
            </Modal>
        }
    }

    const onSaveModal = () => {
        if (state.editProject) {
            updateProject(dispatch, keycloak.token, {...formRef.current.getFormValues(), id: state.editProject.id})
        }else {
            addProject(dispatch, keycloak.token, formRef.current.getFormValues())
        }
        setShowModal(false)
    }

    const onClickAddButton = () => {
        setShowModal(true)
    }

    const onCloseModal = () => {
        setShowModal(false)

        if (state.editCustomer) {
            dispatch({type: PROJECT_EDIT_CANCEL})
        }
    }

    const onClickEdit = (key) => {
        const project = state.projects.find(c => c.id === key)
        dispatch({type: PROJECT_SELECT_EDIT, payload: project})
    }

    return (
        <div >
            <BasicTable loading={false}
                        entries={state.projects}
                        onClickEdit={onClickEdit}
                        columnDefs={[
                            {title: "Name", key: "shortName", type: "text"},
                            {title: "Name Lang", key: "longName1", type: "text"},
                            {title: "Start", key: "start", type: "date"},
                            {title: "Ende", key: "end", type: "date"},
                            {title: "Prj. ID Ext.", key: "idExtern", type: "text"},
                            {title: "", key: "editButton", type: "button"}]} />
            {renderModal()}
            <CircleAddButton className="absolute bottom-8 right-8" onClick={onClickAddButton}/>
        </div>
    )
}
