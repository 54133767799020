import {CUSTOMER_UPDATED, CUSTOMERS_LOAD_ERROR, CUSTOMERS_LOADING} from "../../constants";

const updateCustomer = (dispatch, token, customer) => {
    dispatch({
        type: CUSTOMERS_LOADING,
    });

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(customer)
    };

    let respStatus = 0
    fetch(process.env.REACT_APP_API_URL + `/customers/${customer.id}`, requestOptions)
        .then(resp => {
            respStatus = resp.status
            return resp.json()
        }).then(json => {
            if (respStatus !== 200) {
                dispatch({
                    type: CUSTOMERS_LOAD_ERROR,
                    payload: json
                })
            } else {
                dispatch({
                    type: CUSTOMER_UPDATED,
                    payload: json
                });
            }
        }).catch(err => {
        dispatch({
            type: CUSTOMERS_LOAD_ERROR,
            payload: err
        })
    })
}

export default updateCustomer