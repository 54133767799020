import React, {useState, useRef, useEffect} from 'react';
import Modal from "../layout/Modal";
import CircleAddButton from "../button/CircleAdd";
import BasicTable from "../layout/BasicTable";

import getProjects from "../../state/actions/projects/getProjects";
import {TIMELOG_EDIT_CANCEL, TIMELOG_SELECT_EDIT} from "../../state/constants";
import {useKeycloak} from "@react-keycloak/web";
import {useTimeLogs} from "../../state/context/TimeLogsContext";
import updateTimeLog from "../../state/actions/timelogs/updateTimeLog";
import addTimeLog from "../../state/actions/timelogs/addTimeLog";
import getTimeLogs from "../../state/actions/timelogs/getTimeLogs";

export default function TimeLogs() {

    const [showModal, setShowModal] = useState(false);
    const formRef = useRef()
    const {dispatch, state} = useTimeLogs()
    const {keycloak, initialized} = useKeycloak();

    useEffect(() => {
        if (!initialized) {
            return
        }

        if (!keycloak.authenticated) {
            keycloak.login()
            return
        }

        getTimeLogs(dispatch, keycloak.token)
    }, [dispatch, initialized, keycloak.authenticated])

    useEffect(() => {
        if (state.editTimelog) {
            setShowModal(true)
        }
    }, [state.editTimelog])

    const renderModal = () => {
        if (showModal) {
            return <Modal onClose={onCloseModal} onSave={onSaveModal}>
                {/*<ProjectsForm ref={formRef}/>*/}
            </Modal>
        }
    }

    const onSaveModal = () => {
        if (state.editProject) {
            updateTimeLog(dispatch, keycloak.token, {...formRef.current.getFormValues(), id: state.editProject.id})
        }else {
            addTimeLog(dispatch, keycloak.token, formRef.current.getFormValues())
        }
        setShowModal(false)
    }

    const onClickAddButton = () => {
        setShowModal(true)
    }

    const onCloseModal = () => {
        setShowModal(false)

        if (state.editCustomer) {
            dispatch({type: TIMELOG_EDIT_CANCEL})
        }
    }

    const onClickEdit = (key) => {
        const timeLog = state.timeLogs.find(c => c.id === key)
        dispatch({type: TIMELOG_SELECT_EDIT, payload: timeLog})
    }

    return (
        <div >
            <BasicTable loading={false}
                        entries={state.timeLogs}
                        onClickEdit={onClickEdit}
                        columnDefs={[
                            {title: "Customer", key: "customer", type: "text"},
                            {title: "Project", key: "project", type: "text"},
                            {title: "Activity", key: "activity", type: "text"},
                            {title: "Start", key: "start", type: "date"},
                            {title: "End", key: "end", type: "date"}]} />
            {renderModal()}
            <CircleAddButton className="absolute bottom-8 right-8" onClick={onClickAddButton}/>
        </div>
    )
}
