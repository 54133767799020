import React, {useEffect, useState} from 'react'

export default function ComboBox({values, placeholder, onChange, value}) {

    
    const [inputValue, setInputValue] = useState("")
    const [activeValue, setActiveValue] = useState(false)
    const [lastValue, setLastValue] = useState("")


    useEffect(()=> {
        if(value) {
            if (lastValue !== value) {
                console.log("value changed!")
                const match = values.find(v => v.id === value)
                if (!match) {
                    console.log("WARN: can't find selected value: ", value)
                    return
                }
                setInputValue(match.name)
                setLastValue(match.id)
            }
        }
    }, [value, lastValue, values])

    const renderCell = (v) => {
        return (
            <div key={v.id} className="border border-b-1 p-1 hover:bg-gray-200" onClick={() => {onClickCell(v)}}>
                    <span>{v.name}</span>
            </div>
        )
    }

    const onClickCell = (v) => {
        setInputValue(v.name)
        onChange(v.id)
    }

    const onInputChange = (e) => {
        setInputValue(e.target.value)
        const match = values.find(v => v.name === e.target.value)
        if (match) {
            onChange(match.id)
            console.log("input match: ", match)
        }
    }

    const getValues = () => {

        return values.filter(value => {
            return value.name.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;
        })

    }

    const getDropDownContentClasses = () => {
        if (activeValue) {
            return "absolute z-10 bg-white w-full shadow"
        }
        return "absolute z-10 bg-white w-full h-0 overflow-hidden"
    }

    const onInputFocus = () => {
        setActiveValue(true)
    }

    const onInputBlur = () => {
        setTimeout(()=> {
            setActiveValue(false)
        }, 200)

    }

    return (
        <div className="border relative">
            <input type="text" placeholder={placeholder} onChange={onInputChange} className="w-full p-1 outline-none" onFocus={onInputFocus} onBlur={onInputBlur} value={inputValue}/>
            <div className={getDropDownContentClasses()}>
                {getValues().map(renderCell)}
            </div>
        </div>
    )
}
