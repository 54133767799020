import React, {forwardRef, useEffect, useImperativeHandle} from 'react'
import ComboBox from "../form-fields/ComboBox";
import { useForm, Controller } from "react-hook-form";
import {useCustomers} from "../../state/context/CustomersContext";
import Checkbox from "../form-fields/Checkbox";

const CustomerForm = forwardRef( ( _ , ref ) => {

    const {register, getValues, reset, control} = useForm({defaultValues: {wageWork: 0, wageTravel: 0, travelExpensesFlatRate: 0, travelExpensesReceipt: false}})
    const {state} = useCustomers()

    useImperativeHandle(ref, () => ({

        getFormValues() {
            return getValues()
        }

   }))

    useEffect(() => {
        reset(state.editCustomer)
    }, [reset, state.editCustomer])

    const getMediatedByOptions = () => {
        return state.customers.map(c => {
            return {id: c.id, name: c.shortName}
        })
    }

    return (
        <form>
            <div className="flex flex-wrap">
                <div className="w-1/2 px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="abbreviation">
                        Short Name
                    </label>
                    <input type="text"
                           autoComplete="off"
                           className="border outline-none p-1 w-full"
                           {...register("shortName")}
                    />
                </div>
                <div className="w-1/2 px-3 mb-4" />
                <div className="w-1/2 px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="name">
                        Long Name 1
                    </label>
                    <input type="text"
                           autoComplete="off"
                           className="border outline-none p-1 w-full"
                           {...register("longName1")}
                    />
                </div>
                <div className="w-1/2 px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="name">
                        Long Name 2
                    </label>
                    <input type="text"
                           autoComplete="off"
                           className="border outline-none p-1 w-full"
                           {...register("longName2")}
                    />
                </div>

                <div className="w-1/2 px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="name">
                        Wage Work
                    </label>
                    <input type="number"
                           className="border outline-none p-1 w-full"
                           {...register("wageWork", {valueAsNumber: true})}
                    />
                </div>

                <div className="w-1/2 px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="name">
                        Wage Travel
                    </label>
                    <input type="number"
                           className="border outline-none p-1 w-full"
                           {...register("wageTravel", {valueAsNumber: true})}
                    />
                </div>

                <div className="w-1/2 px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="name">
                        TravelExpensesFlatRate
                    </label>
                    <input type="text"
                           autoComplete="off"
                           className="border outline-none p-1 w-full"
                           {...register("travelExpensesFlatRate")}
                    />
                </div>

                <div className="w-1/2 px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="name">
                        TravelExpensesReceipt
                    </label>
                    <Controller render={({field}) => (
                        <Checkbox onChange={(e)=> field.onChange(e.target.checked)} checked={field.value}/>
                    )} name="travelExpensesReceipt"
                        control={control}
                    />
                </div>

                <div className="w-1/2 px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="name">
                        Customer ID Intern
                    </label>
                    <input type="text"
                           autoComplete="off"
                           className="border outline-none p-1 w-full"
                           {...register("idIntern")}
                    />
                </div>

                <div className="w-1/2 px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="name">
                        Customer ID Extern
                    </label>
                    <input type="text"
                           autoComplete="off"
                           className="border outline-none p-1 w-full"
                           {...register("idExtern")}
                    />
                </div>

                <div className="w-1/2 px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="name">
                        Personalnummer (extern)
                    </label>
                    <input type="text"
                           autoComplete="off"
                           className="border outline-none p-1 w-full"
                           {...register("personnelNumberExt")}
                    />
                </div>

                <div className="w-1/2 px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="name">
                        Vermittelt durch
                    </label>
                    <Controller render={({field}) => (
                        <ComboBox values={getMediatedByOptions()} onChange={(value)=>{field.onChange(value)}} value={field.value}/>
                    )} name="mediatedBy" control={control} />
                </div>

            </div>
        </form>
    )
})

export default CustomerForm