import React from "react";

export default function Modal({onClose, onSave, children}) {
    return (
       <>
           <div
               className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed z-50 outline-none focus:outline-none inset-0">
               <div className="relative w-auto my-6 mx-auto max-w-3xl">
                   <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                       <div className="flex items-start justify-between pl-3 py-1 border-b border-solid border-gray-300 bg-gray-3 rounded-t">
                           <h3 className=" pl-2 text-lg font-normal">New Entry</h3>
                       </div>
                       {/*body*/}
                       <div className="relative p-2 flex-auto">
                           {children}
                       </div>
                       {/*footer*/}
                       <div className="flex items-center justify-end p-2 border-t border-solid border-gray-300 rounded-b">
                           <button
                               className="text-gray-400 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                               type="button"
                               onClick={onClose}
                               style={{ transition: "all .15s ease" }}>
                               Close
                           </button>
                           <button
                               className="bg-primary text-white font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                               type="button"
                               onClick={onSave}
                               style={{ transition: "all .15s ease" }}>
                               Save
                           </button>
                       </div>
                   </div>
               </div>
           </div>
           <div className="opacity-25 fixed inset-0 z-40 bg-black" />
       </>
    )
}