import React, {forwardRef, useEffect, useImperativeHandle} from 'react'
import { useForm, Controller } from "react-hook-form";
import {useCustomers} from "../../state/context/CustomersContext";
import ComboBox from "../form-fields/ComboBox";
import getCustomers from "../../state/actions/customer/getCustomers";
import DateTimePicker from "../form-fields/DateTimePicker";
import {useActivities} from "../../state/context/ActivitiesContext";


const ActivityForm = forwardRef( ( _ , ref ) => {

    const {register, getValues, reset, control} = useForm({defaultValues: {wageWork: 0, wageTravel: 0, travelExpensesFlatRate: 0, fixedPrice: 0,travelExpensesReceipt: false}})
    const {state} = useActivities()
    const {dispatch:customerDispatch, state:customerState} = useCustomers()

    useImperativeHandle(ref, () => ({

        getFormValues() {
            return getValues()
        }

    }))

    useEffect(() => {
        getCustomers(customerDispatch)
    }, [customerDispatch])

    useEffect(() => {
        if (state.editActivity) {
            reset(state.editActivity)
        }
    }, [reset, state.editActivity])

    const getCustomerOptions = () => {
        console.log(customerState)
        return customerState.customers.map(c => {
            return {id: c.id, name: c.shortName}
        })
    }
    //FIXME: htmlFor
    return (
        <form>
            <div className="flex flex-wrap">
                <div className="w-1/2 px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="name">
                        Name
                    </label>
                    <input type="text"
                           autoComplete="off"
                           className="border outline-none p-1 w-full"
                           {...register("name")}
                    />
                </div>

                <div className="w-1/2 px-3 mb-4" />

                <div className="w-1/2 px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="start">
                        Start
                    </label>
                    <Controller render={({field}) => (
                        <DateTimePicker value={field.value} onChange={(value)=>{field.onChange(value)}}/>
                    )} name="start"
                        control={control}
                    />
                </div>
                <div className="w-1/2 px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="end">
                        End
                    </label>
                    <Controller render={({field}) => (
                        <DateTimePicker value={field.value} onChange={(value)=>{field.onChange(value)}}/>
                    )} name="end"
                                control={control}
                    />
                </div>
                <div className="w-1/2 px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="name">
                        Customer
                    </label>
                    <Controller render={({field}) => (
                        <ComboBox values={getCustomerOptions()} onChange={(value)=>{field.onChange(value)}} value={field.value}/>
                    )} name="customer" control={control} />
                </div>

                <div className="w-1/2 px-3 mb-4" />

                <div className="w-1/2 px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="idIntern">
                        ID Intern
                    </label>

                    <input type="text"
                           autoComplete="off"
                           className="border outline-none p-1 w-full"
                           {...register("idIntern")}
                    />
                </div>

                <div className="w-1/2 px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="idExtern">
                        ID Extern
                    </label>
                    <input type="text"
                           autoComplete="off"
                           className="border outline-none p-1 w-full"
                           {...register("idExtern")}
                    />
                </div>

                <div className="w-full px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="description">
                        Description
                    </label>
                    <textarea autoComplete="off" className="border outline-none p-1 w-full resize-none"
                      {...register("description", )}
                    />
                </div>

            </div>
        </form>
    )
})

export default ActivityForm