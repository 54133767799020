import React, {useEffect, useRef, useState} from 'react';
import {DateTime} from 'luxon';
import NumberField from "./NumberField"

export default function DateTimePicker({onChange, value}) {
    
    let dayRef = useRef();
    let monthRef = useRef();
    let yearRef = useRef();

    const [dayValue, setDayValue] = useState(1)
    const [monthValue, setMonthValue] = useState(1)
    const [yearValue, setYearValue] = useState(2021)

    useEffect(() => {
        if (value) {
            setDayValue(value.day)
            setMonthValue(value.month)
            setYearValue(value.year)
        }
    }, [setDayValue, setMonthValue, setYearValue, value])

    const onChangeDay = (value) => {
        // setDayValue(value)
        onChange(DateTime.local(yearValue, monthValue, value, 0, 0))
    }

    const onChangeMonth = (value) => {
        // setMonthValue(value)
        onChange(DateTime.local(yearValue, value, dayValue, 0, 0))
    }

    const onChangeYear = (value) => {
        // setYearValue(value)
        onChange(DateTime.local(value, monthValue, dayValue, 0, 0))
    }

    return (
        <div className="border mx-auto p-1">
            <NumberField min={1} max={31} displayLength={2} 
                toRight={()=> {monthRef.current.setActive()}}
                ref={dayRef}
                onChange={onChangeDay}
                value={dayValue}
                />
            <span>.</span>
            <NumberField min={1} max={12} displayLength={2} 
                toLeft={()=> {dayRef.current.setActive()}}
                toRight={()=> {yearRef.current.setActive()}}
                ref={monthRef}
                onChange={onChangeMonth}
                value={monthValue}
                />
            <span>.</span>
            <NumberField min={1} max={9999} displayLength={4} 
                toLeft={()=> {monthRef.current.setActive()}}
                ref={yearRef}
                onChange={onChangeYear}
                value={yearValue}
                />
        </div>
    )
}
