import React, {forwardRef, useEffect, useImperativeHandle} from 'react'
import { useForm, Controller } from "react-hook-form";
import {useProjects} from "../../state/context/ProjectsContext";
import {useCustomers} from "../../state/context/CustomersContext";
import ComboBox from "../form-fields/ComboBox";
import getCustomers from "../../state/actions/customer/getCustomers";
import DateTimePicker from "../form-fields/DateTimePicker";
import {DateTime} from "luxon";


const ProjectsForm = forwardRef( ( _ , ref ) => {

    const {register, getValues, reset, control} = useForm({defaultValues:
            {wageWork: 0, wageTravel: 0, travelExpensesFlatRate: 0, fixedPrice: 0,travelExpensesReceipt: false, start: DateTime.now(), end: DateTime.now()}})
    const {state} = useProjects()
    const {dispatch:customerDispatch, state:customerState} = useCustomers()

    useImperativeHandle(ref, () => ({

        getFormValues() {
            return getValues()
        }

    }))

    useEffect(() => {
        getCustomers(customerDispatch)
    }, [customerDispatch])

    useEffect(() => {
        if (state.editProject) {
            reset(state.editProject)
        }

    }, [reset, state.editProject])

    const getCustomerOptions = () => {
        console.log(customerState)
        return customerState.customers.map(c => {
            return {id: c.id, name: c.shortName}
        })
    }
    //FIXME: htmlFor
    return (
        <form>
            <div className="flex flex-wrap">
                <div className="w-1/2 px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="abbreviation">
                        Short Name
                    </label>
                    <input type="text"
                           autoComplete="off"
                           className="border outline-none p-1 w-full"
                           {...register("shortName")}
                    />
                </div>
                <div className="w-1/2 px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="name">
                        Long Name 1
                    </label>
                    <input type="text"
                           autoComplete="off"
                           className="border outline-none p-1 w-full"
                           {...register("longName1")}
                    />
                </div>
                <div className="w-1/2 px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="start">
                        Start
                    </label>
                    <Controller render={({field}) => (
                        <DateTimePicker value={field.value} onChange={(value)=>{field.onChange(value)}}/>
                    )} name="start"
                        control={control}
                    />
                </div>
                <div className="w-1/2 px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="end">
                        End
                    </label>
                    <Controller render={({field}) => (
                        <DateTimePicker value={field.value} onChange={(value)=>{field.onChange(value)}}/>
                    )} name="end"
                                control={control}
                    />
                </div>
                <div className="w-1/2 px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="name">
                        Customer
                    </label>
                    <Controller render={({field}) => (
                        <ComboBox values={getCustomerOptions()} onChange={(value)=>{field.onChange(value)}} value={field.value}/>
                    )} name="customer" control={control} />
                </div>

                <div className="w-1/2 px-3 mb-4" />

                {/*<div className="w-1/2 px-3 mb-4">*/}
                {/*    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                {/*           htmlFor="name">*/}
                {/*        Wage Work*/}
                {/*    </label>*/}
                {/*    <input type="number"*/}
                {/*           className="border outline-none p-1 w-full"*/}
                {/*           {...register("wageWork", {valueAsNumber: true})}*/}
                {/*    />*/}
                {/*</div>*/}

                {/*<div className="w-1/2 px-3 mb-4">*/}
                {/*    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                {/*           htmlFor="name">*/}
                {/*        Wage Travel*/}
                {/*    </label>*/}
                {/*    <input type="number"*/}
                {/*           className="border outline-none p-1 w-full"*/}
                {/*           {...register("wageTravel", {valueAsNumber: true})}*/}
                {/*    />*/}
                {/*</div>*/}

                {/*<div className="w-1/2 px-3 mb-4">*/}
                {/*    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                {/*           htmlFor="name">*/}
                {/*        TravelExpensesFlatRate*/}
                {/*    </label>*/}
                {/*    <input type="number"*/}
                {/*           className="border outline-none p-1 w-full"*/}
                {/*           {...register("travelExpensesFlatRate", {valueAsNumber: true})}*/}
                {/*    />*/}
                {/*</div>*/}

                {/*<div className="w-1/2 px-3 mb-4">*/}
                {/*    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                {/*           htmlFor="name">*/}
                {/*        Festpreis*/}
                {/*    </label>*/}
                {/*    <input type="number"*/}
                {/*           autoComplete="off"*/}
                {/*           className="border outline-none p-1 w-full"*/}
                {/*           {...register("fixedPrice")}*/}
                {/*    />*/}
                {/*</div>*/}

                <div className="w-1/2 px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="name">
                        Project ID Intern
                    </label>
                    <input type="text"
                           autoComplete="off"
                           className="border outline-none p-1 w-full"
                           {...register("idIntern")}
                    />
                </div>

                <div className="w-1/2 px-3 mb-4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="name">
                        Project ID Extern
                    </label>
                    <input type="text"
                           autoComplete="off"
                           className="border outline-none p-1 w-full"
                           {...register("idExtern")}
                    />
                </div>

            </div>
        </form>
    )
})

export default ProjectsForm