import React from 'react'
import {
    CUSTOMER_ADDED,
    CUSTOMER_SELECT_EDIT,
    CUSTOMER_EDIT_CANCEL,
    CUSTOMER_UPDATED,
    CUSTOMERS_LOAD_ERROR,
    CUSTOMERS_LOAD_SUCCESS,
    CUSTOMERS_LOADING,
} from "../constants";

const CustomersContext = React.createContext()

const defaultState = {
    customers: [],
    editCustomer: null,
    loading: false,
    error: null
}

const customersReducer = (state, action) => {
    switch (action.type) {
        case CUSTOMER_ADDED: {
            return {
                ...state,
                customers: [...state.customers, action.payload],
                loading: false
            };
        }
        case CUSTOMERS_LOADING: {
            return {
                ...state,
                loading: true
            }
        }
        case CUSTOMER_SELECT_EDIT: {
            return {
                ...state,
                editCustomer: action.payload
            }
        }
        case CUSTOMER_UPDATED: {
            return {
                ...state,
                loading: false,
                editCustomer: null,
                customers: state.customers.map(c => c.id === action.payload.id ? action.payload : c)
            }
        }
        case CUSTOMER_EDIT_CANCEL: {
            return {
                ...state,
                editCustomer: null
            }
        }
        case CUSTOMERS_LOAD_SUCCESS: {
            return {
                ...state,
                loading: false,
                customers: action.payload
            }
        }
        case CUSTOMERS_LOAD_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function CustomersProvider({children}) {
    const [state, dispatch] = React.useReducer(customersReducer, defaultState)
    // NOTE: you *might* need to memoize this value
    // Learn more in http://kcd.im/optimize-context
    const value = {state, dispatch}
    return <CustomersContext.Provider value={value}>{children}</CustomersContext.Provider>
}

const useCustomers = () => {
    const context = React.useContext(CustomersContext)
    if (context === undefined) {
        throw new Error('useCustomers must be used within a CustomersProvider')
    }
    return context
}

export {CustomersProvider, useCustomers}