import React from "react";
import formatDate from "../../utils/dateFormatting";

export default function BasicTableRow({columnDefs, entry, onClickEdit}) {

    const renderRow = () => {
        return columnDefs.map(def => {
            switch (def.type) {
                case "text": {
                    return (
                        <td className="px-6 py-2 whitespace-no-wrap" key={def.key}>
                            <div className="text-sm leading-5 text-gray-900">{entry[def.key]}</div>
                        </td>
                    )
                }
                case "date": {
                    return (
                        <td className="px-6 py-2 whitespace-no-wrap" key={def.key}>
                            <div className="text-sm leading-5 text-gray-900">{formatDate(entry[def.key])}</div>
                        </td>
                    )
                }
                case "button": {
                    return (
                        <td className="px-6 py-2 whitespace-no-wrap text-right text-sm leading-5 font-medium" key={def.key}>
                            <p className="text-indigo-600 hover:text-indigo-900" onClick={onClickEdit}>Edit</p>
                        </td>
                    )
                }
                default: {
                    throw Error("unknown column type: " + def.type)
                }
            }
        })
    }

    return (
        <tr className="hover:bg-blue-50">
            {renderRow()}
        </tr>
    )
}