import {TIMELOGS_LOAD_ERROR, TIMELOGS_LOAD_SUCCESS, TIMELOGS_LOADING} from "../../constants";

const getTimeLogs = async (dispatch, token) => {
    dispatch({
        type: TIMELOGS_LOADING,
    });

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    }

    try {
        const resp = await fetch(process.env.REACT_APP_API_URL + "/entries/_overview?timeframe=last_month", requestOptions)
        const respJson = await resp.json()
        dispatch({
            type: TIMELOGS_LOAD_SUCCESS,
            payload: respJson
        })
    }catch (err) {
        dispatch({
            type: TIMELOGS_LOAD_ERROR,
            payload: err
        })
    }

}

export default getTimeLogs