import {
    PROJECT_UPDATED,
    PROJECTS_LOAD_ERROR,
    PROJECTS_LOADING
} from "../../constants";

const updateProject = (dispatch, token, project) => {
    dispatch({
        type: PROJECTS_LOADING,
    });

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(project)
    };

    let respStatus = 0
    fetch(process.env.REACT_APP_API_URL + `/projects/${project.id}`, requestOptions)
        .then(resp => {
            respStatus = resp.status
            return resp.json()
        }).then(json => {
            if (respStatus !== 200) {
                dispatch({
                    type: PROJECTS_LOAD_ERROR,
                    payload: json
                })
            } else {
                dispatch({
                    type: PROJECT_UPDATED,
                    payload: json
                });
            }
        }).catch(err => {
        dispatch({
            type: PROJECTS_LOAD_ERROR,
            payload: err
        })
    })
}

export default updateProject