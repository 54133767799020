import React from 'react'
import {NavLink, Switch} from 'react-router-dom';
import {Route} from 'react-router'

import Header from '../layout/Header'
import Customers from "../customers/Customers";
import Projects from "../projects/Projects";
import Activities from "../activities/Activities";
import LoginPage from "../login/Login";
import TimeLogs from "../timelog/TimeLogs";

export default function MainLayout() {

    return (
        <div className="flex flex-col min-h-screen h-screen">
            <Header/>
            <div className="flex flex-1 overflow-y-hidden">
                <div className="bg-gray-1 w-40 text-black font-normal text-sm flex flex-col">

                    <NavLink className="px-4 py-2 hover:bg-gray-2"
                             activeClassName="border-l-4 border-gray-400"
                             to="/timelog">
                        Time Logs
                    </NavLink>

                    <NavLink className="px-4 py-2 hover:bg-gray-2"
                                     activeClassName="border-l-4 border-gray-400"
                                     to="/customers">
                                Customers
                            </NavLink>


                            <NavLink className="px-4 py-2 hover:bg-gray-2"
                                     activeClassName="border-l-4 border-gray-400"
                                     to="/projects">
                                Projects
                            </NavLink>


                            <NavLink className="px-4 py-2 hover:bg-gray-2"
                                     activeClassName="border-l-4 border-gray-400"
                                     to="/activities">
                                Activities
                            </NavLink>


                </div>
                <div className="flex-1 flex overflow-y-auto justify-center">
                    <div className="w-full bg-white pl-2 block overflow-hidden h-full">
                        <Switch>
                            <Route exact path="/timelog" component={TimeLogs}/>
                            <Route exact path="/projects" component={Projects}/>
                            <Route exact path="/customers" component={Customers}/>
                            <Route exact path="/activities" component={Activities}/>
                            <Route exact path="/login" component={LoginPage}/>
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
)
}
