import React, {useState, useRef, useEffect} from 'react';
import Modal from "../layout/Modal";
import CircleAddButton from "../button/CircleAdd";
import {useActivities} from "../../state/context/ActivitiesContext";
import {
    ACTIVITY_EDIT_CANCEL,
    ACTIVITY_SELECT_EDIT
} from "../../state/constants";
import BasicTable from "../layout/BasicTable";
import getActivities from "../../state/actions/activities/getActivities";
import ActivityForm from "./ActivityForm";
import addActivity from "../../state/actions/activities/addActivity";
import updateActivity from "../../state/actions/activities/updateActivity";
import {useKeycloak} from "@react-keycloak/web";

export default function Activities() {

    const [showModal, setShowModal] = useState(false);
    const formRef = useRef()
    const {dispatch, state} = useActivities()
    const {keycloak, initialized} = useKeycloak();

    useEffect(() => {
        if (!initialized) {
            return
        }

        if (!keycloak.authenticated) {
            keycloak.login()
            return
        }

        getActivities(dispatch, keycloak.token)
    }, [dispatch, initialized, keycloak.authenticated])

    useEffect(() => {
        if (state.editActivity) {
            setShowModal(true)
        }
    }, [state.editActivity])

    const renderModal = () => {
        if (showModal) {
            return <Modal onClose={onCloseModal} onSave={onSaveModal}>
                <ActivityForm ref={formRef}/>
            </Modal>
        }
    }

    const onSaveModal = () => {
        if (state.editActivity) {
            updateActivity(dispatch, keycloak.token, {...formRef.current.getFormValues(), id: state.editActivity.id})
            // console.log("update activity")
        }else {
            addActivity(dispatch, keycloak.token, formRef.current.getFormValues())
        }
        setShowModal(false)
    }

    const onClickAddButton = () => {
        setShowModal(true)
    }

    const onCloseModal = () => {
        setShowModal(false)

        if (state.editActivity) {
            dispatch({type: ACTIVITY_EDIT_CANCEL})
        }
    }

    const onClickEdit = (key) => {
        const activity = state.activities.find(c => c.id === key)
        dispatch({type: ACTIVITY_SELECT_EDIT, payload: activity})
    }

    return (
        <div >
            <div className="py-2 flex justify-between">
                <span className="font-light text-xl px-2">Activities</span>
                <div>
                    <input type="text" placeholder="search" className="bg-white mr-2 p-1 rounded border border-black outline-none"/>
                </div>
            </div>
            <BasicTable loading={state.loading}
                        entries={state.activities}
                        onClickEdit={onClickEdit}
                        columnDefs={[
                            {title: "Name", key: "name", type: "text"},
                            {title: "ID int.", key: "idIntern", type: "text"},
                            {title: "ID ext.", key: "idExtern", type: "text"},
                            {title: "", key: "editButton", type: "button"}]} />
            {renderModal()}
            <CircleAddButton className="absolute bottom-8 right-8" onClick={onClickAddButton}/>
        </div>
    )
}
