import {
    PROJECT_UPDATED,
    TIMELOGS_LOAD_ERROR,
    TIMELOGS_LOADING
} from "../../constants";

const updateTimeLog = (dispatch, token, entry) => {
    dispatch({
        type: TIMELOGS_LOADING,
    });

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entry)
    };

    let respStatus = 0
    fetch(process.env.REACT_APP_API_URL + `/entries/${entry.id}`, requestOptions)
        .then(resp => {
            respStatus = resp.status
            return resp.json()
        }).then(json => {
            if (respStatus !== 200) {
                dispatch({
                    type: TIMELOGS_LOAD_ERROR,
                    payload: json
                })
            } else {
                dispatch({
                    type: PROJECT_UPDATED,
                    payload: json
                });
            }
        }).catch(err => {
        dispatch({
            type: TIMELOGS_LOAD_ERROR,
            payload: err
        })
    })
}

export default updateTimeLog