import React from 'react'
import {DateTime} from 'luxon'
import {
    ACTIVITY_ADDED,
    ACTIVITIES_LOADING,
    ACTIVITY_SELECT_EDIT,
    ACTIVITY_UPDATED,
    ACTIVITY_EDIT_CANCEL,
    ACTIVITIES_LOAD_SUCCESS,
    ACTIVITIES_LOAD_ERROR,
} from "../constants";

const ActivitiesContext = React.createContext()

const defaultState = {
    activities: [],
    editActivity: null,
    loading: false,
    error: null
}

const activitiesReducer = (state, action) => {
    switch (action.type) {
        case ACTIVITY_ADDED: {
            return {
                ...state,
                activities: [...state.activities, action.payload],
                loading: false
            };
        }
        case ACTIVITIES_LOADING: {
            return {
                ...state,
                loading: true
            }
        }
        case ACTIVITY_SELECT_EDIT: {
            return {
                ...state,
                editActivity: action.payload
            }
        }
        case ACTIVITY_UPDATED: {
            let newAkt = action.payload
            newAkt.start = DateTime.fromISO(newAkt.start)
            newAkt.end = DateTime.fromISO(newAkt.end)
            return {
                ...state,
                loading: false,
                editActivity: null,
                activities: state.activities.map(c => c.id === action.payload.id ? newAkt : c)
            }
        }
        case ACTIVITY_EDIT_CANCEL: {
            return {
                ...state,
                editActivity: null
            }
        }
        case ACTIVITIES_LOAD_SUCCESS: {
            const a = action.payload.map(akt => {
                akt.start = DateTime.fromISO(akt.start)
                akt.end = DateTime.fromISO(akt.end)
                return akt
            })
            return {
                ...state,
                loading: false,
                activities: a
            }
        }
        case ACTIVITIES_LOAD_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function ActivitiesProvider({children}) {
    const [state, dispatch] = React.useReducer(activitiesReducer, defaultState)
    const value = {state, dispatch}
    return <ActivitiesContext.Provider value={value}>{children}</ActivitiesContext.Provider>
}

const useActivities = () => {
    const context = React.useContext(ActivitiesContext)
    if (context === undefined) {
        throw new Error('useActivities must be used within a ActivitiesProvider')
    }
    return context
}

export {ActivitiesProvider, useActivities}