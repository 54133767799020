import React, {useState, useRef, useEffect} from 'react';
import Modal from "../layout/Modal";
import CircleAddButton from "../button/CircleAdd";
import CustomerForm from "./CustomerForm";
import {useCustomers} from "../../state/context/CustomersContext";
import getCustomers from "../../state/actions/customer/getCustomers";
import addCustomer from "../../state/actions/customer/addCustomer";
import {CUSTOMER_SELECT_EDIT, CUSTOMER_EDIT_CANCEL} from "../../state/constants";
import updateCustomer from "../../state/actions/customer/updateCustomer";
import BasicTable from "../layout/BasicTable";
import {useKeycloak} from "@react-keycloak/web";

export default function Customers() {

    const [showModal, setShowModal] = useState(false);
    const formRef = useRef()
    const {dispatch, state} = useCustomers()
    const {keycloak, initialized} = useKeycloak();

    useEffect(() => {
        if (!initialized) {
            return
        }

        if (!keycloak.authenticated) {
            keycloak.login()
            return
        }

        getCustomers(dispatch, keycloak.token)
    }, [dispatch, initialized, keycloak.authenticated])

    useEffect(() => {
        if (state.editCustomer) {
            setShowModal(true)
        }
    }, [state.editCustomer])

    const renderModal = () => {
        if (showModal) {
            return <Modal onClose={onCloseModal} onSave={onSaveModal}>
                <CustomerForm ref={formRef}/>
            </Modal>
        }
    }

    const onSaveModal = () => {
        if (state.editCustomer) {
            updateCustomer(dispatch, keycloak.token, {...formRef.current.getFormValues(), id: state.editCustomer.id})
        }else {
            addCustomer(dispatch, keycloak.token, formRef.current.getFormValues())
        }
        setShowModal(false)
    }

    const onClickAddButton = () => {
        setShowModal(true)
    }

    const onCloseModal = () => {
        setShowModal(false)

        if (state.editCustomer) {
            dispatch({type: CUSTOMER_EDIT_CANCEL})
        }
    }

    const onClickEdit = (key) => {
        const customer = state.customers.find(c => c.id === key)
        dispatch({type: CUSTOMER_SELECT_EDIT, payload: customer})
    }

    return (
        <div >
            <BasicTable loading={state.loading}
                        entries={state.customers}
                        onClickEdit={onClickEdit}
                        columnDefs={[
                            {title: "Kürzel", key: "shortName", type: "text"},
                            {title: "Name", key: "longName1", type: "text"},
                            {title: "Kundennummer int.", key: "idIntern", type: "text"},
                            {title: "Kundennummer ext.", key: "idExtern", type: "text"},
                            {title: "", key: "editButton", type: "button"}]} />
            {renderModal()}
            <CircleAddButton className="absolute bottom-8 right-8" onClick={onClickAddButton}/>
        </div>
    )
}
