import React from 'react'
import {DateTime} from 'luxon'
import {
    TIMELOG_ADDED,
    TIMELOG_SELECT_EDIT,
    TIMELOG_EDIT_CANCEL,
    TIMELOG_UPDATED,
    TIMELOGS_LOAD_ERROR,
    TIMELOGS_LOAD_SUCCESS,
    TIMELOGS_LOADING,
} from "../constants";

const TimeLogsContext = React.createContext()

const defaultState = {
    timeLogs: [],
    sum: 0,
    editTimelog: null,
    loading: false,
    error: null
}

const timeLogsReducer = (state, action) => {
    switch (action.type) {
        case TIMELOG_ADDED: {
            return {
                ...state,
                timeLogs: [...state.timeLogs, action.payload],
                loading: false
            };
        }
        case TIMELOGS_LOADING: {
            return {
                ...state,
                loading: true
            }
        }
        case TIMELOG_SELECT_EDIT: {
            return {
                ...state,
                editTimelog: action.payload
            }
        }
        case TIMELOG_UPDATED: {
            let t = action.payload
            t.start = DateTime.fromISO(t.start)
            t.end = DateTime.fromISO(t.end)
            return {
                ...state,
                loading: false,
                editTimelog: null,
                timeLogs: state.timeLogs.map(c => c.id === action.payload.id ? t : c)
            }
        }
        case TIMELOG_EDIT_CANCEL: {
            return {
                ...state,
                editTimelog: null
            }
        }
        case TIMELOGS_LOAD_SUCCESS: {
            const p = action.payload.rows.map(pr => {
                pr.start = DateTime.fromISO(pr.start)
                pr.end = DateTime.fromISO(pr.end)
                return pr
            })
            return {
                ...state,
                loading: false,
                timeLogs: p,
                sum: action.payload.duration_sum_minutes,
            }
        }
        case TIMELOGS_LOAD_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function TimeLogsProvider({children}) {
    const [state, dispatch] = React.useReducer(timeLogsReducer, defaultState)
    const value = {state, dispatch}
    return <TimeLogsContext.Provider value={value}>{children}</TimeLogsContext.Provider>
}

const useTimeLogs = () => {
    const context = React.useContext(TimeLogsContext)
    if (context === undefined) {
        throw new Error('useTimeLogs must be used within a TimeLogsProvider')
    }
    return context
}

export {TimeLogsProvider, useTimeLogs}