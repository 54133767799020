import React from "react";

export default function Header() {
    return (
        <div className="bg-white flex text-black  border-b z-50 h-10">
            <div className="w-56 flex-none flex items-center justify-between px-3 py-2">
                <div className="text-l font-semibold">Time Manager</div>
            </div>
            <div className="flex-1 flex items-center justify-between px-4">
                <div className="flex items-center">
                    {/*Hello*/}
                </div>
                <div>Logout</div>
            </div>
        </div>
    )
}