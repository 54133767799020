import {DateTime} from "luxon";

const formatDate = (date) => {
    if (typeof date === 'string') {
        date = new DateTime.fromISO(date)
    }

    if (!isValidDate(date)) {
        return "-"
    }

    return date.toFormat('dd.MM.yyyy');
}

const isValidDate = (d) => {
    return d instanceof DateTime && !isNaN(d);
}

export default formatDate