import {PROJECTS_LOAD_ERROR, PROJECTS_LOAD_SUCCESS, PROJECTS_LOADING} from "../../constants";

const getProjects = (dispatch, token) => {
    dispatch({
        type: PROJECTS_LOADING,
    });

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    }

    fetch(process.env.REACT_APP_API_URL + "/projects", requestOptions)
        .then(resp => resp.json())
        .then(json => {
            dispatch({
                type: PROJECTS_LOAD_SUCCESS,
                payload: json
            });
        }).catch(err => {
            dispatch({
                type: PROJECTS_LOAD_ERROR,
                payload: err
            })
    })
}

export default getProjects